import React from "react"
import styled from "styled-components"
import SiteLink from "../../bits/siteLink"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import { graphql, useStaticQuery } from "gatsby"
import DownloadLink from '../../bits/downloadLink'
import cp86 from "../../static/images/cp86.png"
import cp86resources from "../../static/images/cp86resources.png"

const ImageContainer = styled.div`
  height: 100%;
  margin: 10px;
  align-items: center;
  margin-bottom:15px;
  justify-content: center;
  border: 3px solid #ff9f00;

  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0 25px 40px 0 rgba(0,108,255, 0.28);
    border:none;
  }

`

const CP86Info = () => {

    const data = useStaticQuery(graphql`
    query {
      cp86: file(relativePath: { eq: "cp86.png" }) {
        childImageSharp {
          fluid(maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  
      cp86resources: file(relativePath: { eq: "cp86resources.png" }) {
          childImageSharp {
            fluid(maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `)
  return (
    <div>
        <Fade bottom>
            <ImageContainer>
                <DownloadLink href={cp86} download>
                    <Img fluid={data.cp86resources.childImageSharp.fluid} alt="CP86 Resources" />
                </DownloadLink>
            </ImageContainer>
        </Fade>

        <Fade bottom>
            <ImageContainer>
                <DownloadLink href={cp86resources} download>
                    <Img fluid={data.cp86.childImageSharp.fluid} alt="CP86" />
                </DownloadLink>
            </ImageContainer>
        </Fade>
    </div>
  )
}

export default CP86Info;


