import React from 'react';
import styled from 'styled-components';
import Layout from '../template/index';
import SEO from '../utils/seo';
import ReactPlayer from 'react-player/lazy';
import Boxed from "../bits/box";
import CP86Info from "../components/CP86Info/index"
import Column from "../bits/column"
import cp86_file from '../static/CP86-Overview.pdf';
import dpOversight from '../static/DP-Oversight.pdf';
import streamline from '../static/Streamline.pdf';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import DownloadLink from '../bits/downloadLink'







const Intro = styled.div`
  grid-column: span 5 / 8;
  background: var(--color-background-500);
  padding: 2rem;
  z-index: 1;
  margin-left: 16px;
  @media only screen and (max-width: 768px) {
    padding: 0;
    background: none;
  }
`


const ColumnRow = styled.section`
  padding: var(--var-padding-m) 16px;
  background: white;
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
`
const TopColumn = styled.div`
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;

  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`
const VideoWrapper = styled.div`
  margin-top: 100px;

  

  @media screen and (max-width: 600px) {
    margin-top: -10px;
    margin-bottom: -10px;
  }
`
const Text = styled.p`
  text-align: justify;
  margin-right: 20px;
`
const Header = styled.h3`
  margin-top: 0px;  
`

const Box = styled(Boxed)`
  max-width: var(--page-container-l);
`

const Row = styled.section`
  padding: var(--var-padding-m) 16px;
  background: white;
`


const GreyRow = styled(Row)`
  background: var(--color-white-300);
`

const ColumnSpaced = styled(Column)`
  grid-gap: 3rem;
`

const StickyBox = styled.div`
  height: 100%;
`

const StickyWrapper = styled.div`
  position: relative;
  padding: var(--var-padding-m) 0;

  @media only screen and (min-width: 600px) {
    position: sticky;
    top: 3rem;
  }
`

export default () => {
  return(
    <Layout>
        <SEO title="CP86" />
        <ColumnRow>
        <TopColumn>
        <Intro>
        <small>Overview</small>
        <Header>CP86</Header>
          <Text>
            Consultation Paper 86, otherwise known as <b>CP86</b> was introduced by the Central Bank of Ireland 
            <b> ('Central Bank')</b> to identify standards of industry compliance in order to form a supervisory 
            approach for fund management companies <b>('ManCos')</b> and to ensure that the required effectiveness 
            and systems of governance are in place to protect investors’ best interests. 


            Built to meet the ever changing needs of ManCos, <b>GECKO</b> allows firms to focus on meeting their 
            legal and regulatory CP86 requirements. Providing a holistic, real-time view of delegates, tasks 
            and responsibilities, all activity is recorded and stored, with the underlying information available 
            at the click of a button.
          </Text>
        </Intro>
        </TopColumn>
        <TopColumn>
        <VideoWrapper>
        <ReactPlayer 
        url='https://vimeo.com/527841182'
        width="100%"
        controls={true}/>             <br />
        </VideoWrapper>
        <h4>CP86 Implementation</h4>
        <Text>
        Patrick Purcell, Head of Product, explains how GECKO fits smoothly into your CP86 Action Plan. The GECKO 
        system is already operational and deployments across multiple departments can be completed with speed and simplicity.
        </Text>
        </TopColumn>
        </ColumnRow>

        <GreyRow className="full-bleed">
          <Box>
            <ColumnSpaced>
              <StickyBox>
                <StickyWrapper>
                  <br />
                  <br />
                  <h2 className="no-margin">CP86</h2>
                  <p>
                  A snapshot of how GECKO can free up resources for your entire organisation 
                  and focus on the key CP86 deliverables.
                  </p>
                </StickyWrapper>
              </StickyBox>
              <CP86Info />
            </ColumnSpaced>
          </Box>
      </GreyRow>   
      <Row>
        <Boxed>
            <small>Curated list of essential CP86 materials</small>
            <h2 className="no-margin">Downloads</h2>
            <br/>
            <DownloadLink href={cp86_file} download><b>CP86 Compliance Solution Report </b>
              <FontAwesomeIcon icon={faDownload} />
            </DownloadLink>
            <br/>
            <DownloadLink href={dpOversight} download><b>Designated Persons (DPs) Oversight Report</b>
              <FontAwesomeIcon icon={faDownload} />
            </DownloadLink> 
            <br/>
            <DownloadLink href={streamline} download><b>GECKO Streamline Report</b>
              <FontAwesomeIcon icon={faDownload} />
            </DownloadLink> 
        </Boxed>
      </Row>
            
    </Layout>
  )
}